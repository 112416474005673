import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import multiTenant from './multiTenant'

export const RenderByPlatform = ({ platform, roles, name, children }) => {
  const { route } = useRouter()
  const matchedComponent = multiTenant?.[route]?.[name]

  let isAccessable = useMemo(() => {
    if (!matchedComponent) {
      return false
    }
    const isPlatformStrict = matchedComponent?.platform?.length > 0
    const isRolesStrict = matchedComponent?.roles?.length > 0

    if (isPlatformStrict && !matchedComponent?.platform?.includes(platform)) {
      return false
    }

    if (isRolesStrict && !matchedComponent?.roles?.some((i) => roles.includes(i))) {
      return false
    }

    return true
  }, [children])

  return isAccessable ? children : null
}

const mapStateToProps = ({ layout, user }) => ({
  platform: layout?.platform?.platform,
  roles: user?.roles,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RenderByPlatform)

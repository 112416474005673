import { Col, Row } from 'antd'
import React from 'react'

const MOIT_NOTI_LINK = 'http://online.gov.vn/Home/WebDetails/87764?AspxAutoDetectCookieSupport=1'

export const Footer = () => {
  return (
    <Row className={'footer'}>
      <Col span={24} className={'container'}>
        <div className={'footer__topline'} />
        <Row className={'footer__content'} gutter={0} justify={'space-between'}>
          <Col flex={2} className={'footer__content__description'}>
            <div className={'description__group'} style={{ maxWidth: 320 }}>
              <img src={'/assets/png/footer-company.png'} />
              <span>CÔNG TY TNHH CÔNG NGHỆ VÀ DỊCH VỤ DTRACK</span>
            </div>
            <div className={'description__group'} style={{ maxWidth: 320 }}>
              <img src={'/assets/png/footer-location.png'} />
              <span>
                Đ/C: Tầng 1, Tòa nhà International Plaza, Số 343 Phạm Ngũ Lão, Phường Phạm Ngũ Lão,
                Quận 1, Thành phố Hồ Chí Minh, Việt Nam
              </span>
            </div>
            <div className={'description__group'} style={{ maxWidth: 320 }}>
              <img src={'/assets/png/foodmap-cert.png'} />
              <span>
                Giấy chứng nhận Đăng ký Kinh doanh số <b>0317537788</b> do Sở Kế hoạch và Đầu tư
                Thành phố Hồ Chí Minh cấp ngày 26/10/2022.
              </span>
            </div>
          </Col>
          <Col flex={1} className={'footer__content__description'}>
            <div className={'description__group'}>
              <img src={'/assets/png/footer-email.png'} />
              <span>info@dtrack.asia</span>
            </div>
            <div className={'description__group'}>
              <img src={'/assets/png/footer-phone.png'} />
              <span>Hotline: 0903 344 277</span>
            </div>
          </Col>
          <Col flex={1} className={'footer__content__description'}>
            <div className={'description__group'}>
              <a href="#">Điều khoản và quy định chung</a>
            </div>
            <div className={'description__group'}>
              <a href="#">Hình thức thanh toán</a>
            </div>
            <div className={'description__group'}>
              <a href="/information-security">Bảo mật thông tin</a>
            </div>
          </Col>
          <Col flex={1} className={'footer__content__description'}>
            <div className={'description__group'}>
              <a href={MOIT_NOTI_LINK} target="blank">
                <img src={'/assets/png/footer-moit-noti.png'} />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Footer
